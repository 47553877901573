import React, {useEffect, useState} from "react"

import Header from "./header"
import Footer from "./footer"
import Background from "./background"
import "../scss/header.scss"

const Layout = ({ path, children }) => {
  const [bgClass, setBgClass] = useState('homeBg')
  const [headerTheme, setHeaderTheme] = useState('lightHeader')

  useEffect(() => {
    setBgClass('homeBg')
    setHeaderTheme('lightHeader')
    const splits = path.split('/')
    const route = splits[1] !== 'products' ? splits[1] : splits[2]

    route ? setBgClass(route.toLowerCase() + 'Bg') : setBgClass('homeBg')

    if (['blogBg', 'youdataBg'].includes(bgClass)) {
      setHeaderTheme("darkHeader")
    }
  })

  return (
    <>
      <Background bgClass={bgClass} />
      <div></div>
      <Header headerTheme={headerTheme} />

      <main role="main">

          <div id="page">{children}</div>

          <div id="footer"><Footer /></div>
      </main>
    </>
  )
}

export default Layout
