import React from "react"
import { Link } from "gatsby"
import "../scss/footer.scss"

import YoumedicoLogo from "../assets/img/youMedico/medico_1.svg"
import TwitterLogo from "../assets/img/youpalGroup/logo-twitter.png"
import FacebookLogo from "../assets/img/youpalGroup/logo-facebook.png"
import InstagramLogo from "../assets/img/youpalGroup/logo-instagram.png"
import LinkedInLogo from "../assets/img/youpalGroup/logo-linkedin.png"

import { FaTwitter } from 'react-icons/fa'
import { FaFacebookSquare } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className="container">
      <div className="row footerRow">
        <div className="footerLogo">
          <YoumedicoLogo style={{ height: '56px', width: 'auto' }} />
        </div>
        <div className="row">
          <div className="footerMenu row">
            <div><Link to="/privacy-policy">Privacy Policy</Link></div>
            <div><Link to="/terms-and-conditions">Terms & Conditions</Link></div>
            <div><Link to="/contact-us">Support</Link></div>
          </div>
          <div className="footerSocials">
            <a href="https://twitter.com/YouMedico" target="_blank">
              <FaTwitter className="footer-icon icon-twitter-hover"/>
            </a>
            <a href="https://www.facebook.com/YouMedico-103346685053688" target="_blank">
              <FaFacebookSquare className="footer-icon icon-facebook-hover"/>
            </a>
            <a href="https://www.instagram.com/youmedico/" target="_blank">
              <FaInstagram className="footer-icon icon-instagram-hover"/>
            </a>
            <a href="https://www.linkedin.com/showcase/you-medico" target="_blank">
              <FaLinkedin className="footer-icon icon-linkedin-hover"/>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
