import React, { Component } from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import ChevronDown from "../assets/img/youpalGroup/chevron-down.svg"

import "../scss/header.scss"

import HomePageLogo from "../assets/img/youMedico/medico_1.svg"
import MenuIcon from "../assets/img/youpalGroup/menu-icon.png"

class Header extends Component {
  constructor(props) {
    super(props)

    this.myRef = React.createRef()

    this.state = {
      isSticky: false,
      selectedNav: "",
      isNavOpen: false,
      links: ["Global", "RPM", "Telemedicine"],
    }

    this.expandNavSection = this._expandNavSection.bind(this)
    this.resetNavSection = this._resetNavSection.bind(this)

    window.addEventListener("scroll", () => {
      if (window.scrollY > 150) {
        this.setState({
          isSticky: true,
        })
      } else if (window.scrollY < 100) {
        this.setState({
          isSticky: false,
        })
      }
    })
  }

  _resetNavSection() {
    this.setState({
      selectedNav: "",
      isNavOpen: false,
    })
  }

  _expandNavSection(event) {
    const navValue = event.target.querySelector("span")?.textContent

    this.setState({
      selectedNav: navValue,
    })
  }

  render() {
    const { isSticky, selectedNav, links, isNavOpen } = this.state

    const showNav = (nav) =>
      selectedNav?.toLowerCase().includes(nav) ? "show" : ""

    const handleLinkClick = () => {
      this.setState({
        isNavOpen: false,
      })
    }

    const handleTogglerClick = () => {
      this.setState({
        isNavOpen: !isNavOpen,
      })
    }

    const hClass = classnames(
      "navbarDiv",
      "sticky-top",
      isSticky ? "compactNav" : this.props.headerTheme
    )

    const togglerClass = classnames(
      "navbar-toggler",
      isNavOpen ? "" : "collapsed"
    )

    const collapseClass = classnames(
      "navbar-collapse",
      "collapse",
      isNavOpen ? "show" : ""
    )

    const logoClass = classnames("navbar-brand")

    return (
      <div className={hClass}>
        <nav className="navbar navbar-expand-lg">
          <Link className={logoClass} to="/" onClick={handleLinkClick}>
            <HomePageLogo style={{ maxHeight: '200px', height: '60px' }} />
          </Link>
          <a
            className={togglerClass}
            type="button"
            data-toggle="collapse"
            data-target="#navbarTop"
            aria-controls="navbarTop"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleTogglerClick}
          >
            <img src={MenuIcon} />
          </a>

          <div className={collapseClass} id="navbarTop">
            <ul className="mr-auto navbar-nav">
              <li className="px-4">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={handleLinkClick}
                  aria-haspopup="true"
                >
                  <span>Home</span>
                </Link>
              </li>
              <li
                className={classnames(
                  "nav-item",
                  "dropdown",
                  showNav("products")
                )}
                onMouseEnter={this.expandNavSection}
                onMouseLeave={this.resetNavSection}
              >
                <a
                  className="nav-link dropdown-toggle"
                  href=""
                  id="dropdown02"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={showNav("products") === "show"}
                >
                  <span>Products & Solutions</span>
                  <img src={ChevronDown} />
                </a>
                <div
                  className={classnames(
                    "dropdown-menu",
                    "dropdown-menu-right",
                    showNav("products")
                  )}
                  onMouseLeave={this.resetNavSection}
                  aria-labelledby="dropdown02"
                >
                  {links.map((product, index) => (
                    <Link
                      key={"mainlinkproducts" + index}
                      className="dropdown-item"
                      to="/services/"
                      onClick={this.resetNavSection}
                    >
                      {product}
                    </Link>
                  ))}
                </div>
              </li>
              <li className="px-4">
                <Link
                  className="nav-link"
                  to="/for-providers"
                  aria-haspopup="true"
                  onClick={handleLinkClick}
                >
                  <span>For Providers</span>
                </Link>
              </li>
              <li className="px-4">
                <Link
                  className="nav-link"
                  to="/contact-us"
                  aria-haspopup="true"
                  onClick={handleLinkClick}
                >
                  <span>Contact Us</span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}

export default Header
